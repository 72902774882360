// | Clamp | Image Fit | Default Zoom                            | Min Zoom                                | Max Zoom                             | Move Out Of Bounds |
// |-------|-----------|-----------------------------------------|-----------------------------------------|--------------------------------------|--------------------|
// | FALSE | fill      | biggest side as per Aspect Ratio        | minZoomDefault * 0.15                   | maxZoomDefault * 0.5                 | Yes                |
// | TRUE  | fill      | biggest side as per Aspect Ratio        | biggest side as per Aspect Ratio        | maxZoomDefault                       | No                 |
// | FALSE | fit       | biggest side as per Aspect Ratio        | minZoomDefault * 0.15                   | maxZoomDefault * 0.5                 | Yes                |
// | TRUE  | fit       | biggest side as per Aspect Ratio        | biggest side as per Aspect Ratio        | maxZoomDefault                       | No                 |
// | FALSE | none      | biggest side as per Aspect Ratio * 0.85 | minZoomDefault * 0.15                   | maxZoomDefault * 0.5                 | Yes                |
// | TRUE  | none      | biggest side as per Aspect Ratio * 0.85 | biggest size as per Aspect Ratio * 0.15 | biggest size as per Aspect Ratio * 4 | No

import { getDefaultImageTransformation } from "~/services/Utils"
import { Size } from "~/services/cropHelpers"
import { tObjectFit } from "@jackfruit/common"
import { round } from "lodash"

type tImageFitSetting = {
  imageFit: tObjectFit
  clamp: boolean
  defaultZoom: number
  minZoom: number
  maxZoom: number
  zoomStep: number
}

type tImageFitZoomSetting = {
  [key: string]: tImageFitSetting
}

const lenientZoomStep = 0.1

export const calcZoomSettings = (
  isClamp: boolean,
  imageFit: tObjectFit = "fill",
  regionSize: Size,
  imageSize: Size
) => {
  const defaultTransform = getDefaultImageTransformation(
    regionSize,
    imageSize,
    0,
    imageFit,
    isClamp
  )

  return {
    settingZoom: defaultTransform.zoom,
    settingMaxZoom: defaultTransform.maxZoom,
    settingMinZoom: defaultTransform.minZoom,
    settingZoomStep: defaultTransform.zoomStep,
  }
}

export const getTypeOfImage = (
  imageFit: tObjectFit,
  clamp: boolean,
  aspectRatio: number,
  minZoomDefault: number,
  maxZoomDefault: number,
  zoomStepDefault: number
) => {
  const key = `${imageFit.toLowerCase()}Clamp${clamp ? "True" : "False"}`

  const zoomSetting = imageFitZoomSetting(
    aspectRatio,
    key,
    minZoomDefault,
    maxZoomDefault,
    zoomStepDefault
  )

  // ensure maxZoom > zoomDefault
  let maxZoom = zoomSetting.maxZoom

  if (zoomSetting.defaultZoom > zoomSetting.maxZoom) {
    const zoomRatio = round(aspectRatio / maxZoomDefault, 5)
    maxZoom = zoomSetting.maxZoom / zoomRatio
  }

  return { ...zoomSetting, maxZoom }
}

export const imageFitZoomSetting = (
  aspectRatio: number,
  key: string,
  minZoomDefault: number,
  maxZoomDefault: number,
  zoomStepDefault: number
) => {
  const value: tImageFitZoomSetting = {
    fillClampFalse: {
      imageFit: "fill",
      clamp: false,
      defaultZoom: aspectRatio,
      minZoom: minZoomDefault * 0.15,
      maxZoom: maxZoomDefault * 0.5,
      zoomStep: zoomStepDefault,
    },
    fillClampTrue: {
      imageFit: "fill",
      clamp: true,
      defaultZoom: aspectRatio,
      minZoom: aspectRatio,
      maxZoom: maxZoomDefault,
      zoomStep: zoomStepDefault,
    },
    fitClampFalse: {
      imageFit: "fit",
      clamp: false,
      defaultZoom: aspectRatio,
      minZoom: minZoomDefault * 0.15,
      maxZoom: maxZoomDefault * 0.5,
      zoomStep: zoomStepDefault,
    },
    fitClampTrue: {
      imageFit: "fit",
      clamp: true,
      defaultZoom: aspectRatio,
      minZoom: aspectRatio,
      maxZoom: maxZoomDefault,
      zoomStep: zoomStepDefault,
    },
    noneClampFalse: {
      imageFit: "none",
      clamp: true,
      defaultZoom: aspectRatio * 0.85,
      minZoom: minZoomDefault * 0.15,
      maxZoom: maxZoomDefault * 0.5,
      zoomStep: lenientZoomStep,
    },
    noneClampTrue: {
      imageFit: "none",
      clamp: false,
      defaultZoom: aspectRatio * 0.85,
      minZoom: aspectRatio * 0.15,
      maxZoom: aspectRatio * 4,
      zoomStep: lenientZoomStep,
    },
  }
  return value[key] || value["fillClampTrue"]
}
