/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import ApplicationWrapper from "../src/ApplicationWrapper"
import PageWrapper from "../src/PageWrapper"

export const wrapRootElement = ApplicationWrapper
export const wrapPageElement = PageWrapper

// UNCOMMENT TO ENABLE WDYR
// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== "production") {
//     const whyDidYouRender = require("@welldone-software/why-did-you-render")
//     whyDidYouRender(React, {
//       trackAllPureComponents: false,
//     })
//   }
// }
