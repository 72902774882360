import { Box } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import ReactDOM from "react-dom"
import { renderToString } from "react-dom/server"
import Carousel from "~/components/carousel/Carousel"
import { ShortCodeParser } from "../replaceShortCodes"
import { isBrowser } from "../Utils"

// Usage:
//
//[carousel id=1 width="100%" height=400 delay=0
//    carouselStyle='{"borderRadius": 5, "mt": 6, "mb": 7, "color": "blue" }'
//    thumbnailStyle='{"width": 120, "height": 120, "borderRadius": 5, "borderColor": "#f9f8f7", "borderFocusColor": "#aabca7"}'
//    circleStyle='{"size": 10, "mx": 3, "borderColor": "#f9f8f7", "borderFocusColor": "#aabca7"}'
//    thumbnailIndicatorSize='{"md": "80px", "lg": "100px", "xl": "120px"}'
//    mobileThumbnailType="circle" or "image"
//]
//   [image src="https://media.istockphoto.com/photos/various-fresh-ripe-pumpkins-as-background-picture-id1277767891" alt="image one" showThumbnails=0 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]
//   [image src="https://media.istockphoto.com/photos/asian-chinese-mid-adult-woman-helping-her-father-in-the-farm-picture-id1268636389" alt="image two"  showThumbnails=1 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]]
//   [image src="https://media.istockphoto.com/photos/autumn-leaves-falling-on-the-ground-in-a-park-picture-id1278765757" alt="image three"  showThumbnails=1 srcThumbnail="https://autopilot-webapp.s3.amazonaws.com/production/assets/e65d954622cdec137225d78c8a9d1751.jpeg"]]
// [/carousel]
export const parser: ShortCodeParser = (html: string = "") => {
  return replace("carousel", html, args => {
    const {
      width = 300,
      height = 200,
      delay = 3000,
      id = "1",
      carouselstyle = "{}",
      thumbnailstyle = "{}",
      circlestyle = "{}",
      thumbnailindicatorsize = "{}",
      mobilethumbnailtype = "circle",
    } = args.attrs.named
    const content = args.content ?? ""
    const images = imageParser(content)
    const thumbnailIndicatorSizeParsed = JSON.parse(thumbnailindicatorsize)
    const carouselStyleParsed = JSON.parse(carouselstyle)
    const circleStyleParsed = JSON.parse(circlestyle)
    const thumbnailStyleParsed = JSON.parse(thumbnailstyle)

    if (images.length === 0) {
      return `[carousel error="no images provided"]`
    }

    const elementId = `image-carousel-${id}`

    if (isBrowser) {
      setTimeout(() => {
        const el = document.getElementById(elementId)
        if (el) {
          ReactDOM.render(
            <Carousel
              width={width}
              height={Number(height)}
              delay={Number(delay)}
              images={images}
              carouselStyle={carouselStyleParsed}
              circleStyle={circleStyleParsed}
              thumbnailStyle={thumbnailStyleParsed}
              thumbnailIndicatorSize={thumbnailIndicatorSizeParsed}
              mobileThumbnailType={mobilethumbnailtype}
            />,
            el
          )
        }
      })
    }

    return renderToString(<Box id={elementId} />)
  })
}

const imageParser = (html: string = "") => {
  const all: any[] = []
  replace("image", html, args => {
    all.push(args.attrs.named)

    return ""
  })

  return all
}
