import {
  Button,
  ChakraProvider,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  useClipboard,
  VStack,
} from "@chakra-ui/react"
import { AppEntityHydrated } from "@jackfruit/common"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "next-share"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiLink } from "react-icons/fi"
import { useLocation } from "react-use"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"

interface Props {
  config: AppEntityHydrated
}

const SocialMediaSharer: React.FC<Props> = ({ config }) => {
  const { t } = useTranslation()
  const theme = makeTheme(config)
  const location = useLocation()
  const url = location.href ?? ""

  const { onCopy } = useClipboard(url)

  return (
    <ChakraProvider theme={theme}>
      <Stack w={60} my={8} direction="column" justify="center">
        <Heading fontSize="md">Share the love</Heading>
        <HStack spacing={4} justify="flex-start">
          <FacebookShareButton url={url} blankTarget>
            <FacebookIcon size={48} round />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={48} round />
          </TwitterShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={48} round />
          </LinkedinShareButton>
        </HStack>

        <VStack align="flex-start" w={56}>
          <Text fontSize="xs">
            {t("component.short-codes.SocialMediaSharer.CopyLink")}
          </Text>
          <InputGroup size="sm" borderRadius="sm">
            <InputLeftElement w={6} children={<FiLink color="gray.300" />} />
            <Input
              pl={6}
              pr={14}
              fontSize="xs"
              borderColor="gray.300"
              value={url}
              isReadOnly
            />
            <InputRightElement w={14}>
              <Button
                h={7}
                size="md"
                fontSize="xs"
                mr={0.5}
                color="white"
                bg="purple.500"
                borderRadius="sm"
                onClick={onCopy}
              >
                {t("component.short-codes.SocialMediaSharer.CopyButton")}
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
      </Stack>
    </ChakraProvider>
  )
}

export default SocialMediaSharer
