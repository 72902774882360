import { replace } from "@wordpress/shortcode"
import React from "react"
import ReactDOM from "react-dom"
import { renderToString } from "react-dom/server"
import SocialMediaSharer from "~/components/short-codes/SocialMediaSharer"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"
import { isBrowser } from "../Utils"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("shareOnSocial", html, args => {
    const { id = "1" } = args.attrs.named
    const elementId = `share-on-social-${id}`

    if (isBrowser) {
      setTimeout(() => {
        const el = document.getElementById(elementId)
        if (el) {
          ReactDOM.render(<SocialMediaSharer config={state.appConfig!} />, el)
        }
      })
    }

    return renderToString(<div id={elementId}></div>)
  })
}
