import { ChakraProvider } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import ReactDOM from "react-dom"
import { renderToString } from "react-dom/server"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"
import CountrySelector from "~/components/short-codes/CountrySelector"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"
import { isBrowser } from "../Utils"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("countrySelect", html, args => {
    const {
      id = "1",
      default: defaultValue = "",
      options = "{}",
    } = args.attrs.named
    const parsedOptions = JSON.parse(options)

    const theme = makeTheme(state.appConfig!)

    const elementId = `country-selector-${id}`

    if (isBrowser) {
      setTimeout(() => {
        const el = document.getElementById(elementId)
        if (el) {
          ReactDOM.render(
            <ChakraProvider theme={theme}>
              <CountrySelector value={defaultValue} options={parsedOptions} />
            </ChakraProvider>,
            el
          )
        }
      })
    }

    return renderToString(<div id={elementId}></div>)
  })
}
