import { Button } from "@chakra-ui/button"
import {
  Flex,
  Icon,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react"
import React from "react"
import { FiChevronDown, FiGlobe } from "react-icons/fi"
import { getCountryName } from "~/services/Utils"

interface Options {
  [key: string]: string
}

interface Props {
  value: string
  options: Options
}

const CountrySelector: React.FC<Props> = ({ value, options }) => {
  const currentCountryName = getCountryName(value)
  const listOfOptions = Object.keys(options)
    .map(countryCode => {
      return {
        label: getCountryName(countryCode),
        url: options[countryCode],
      }
    })
    .sort((a, b) => {
      return a.label === currentCountryName ? -1 : 1
    })

  return (
    <Menu>
      <MenuButton
        className="p-footer-country-selector"
        name="p-footer-country-selector"
        as={Button}
        colorScheme="grey"
        bg="white"
        variant="ghost"
      >
        <Flex justifyContent="center" alignItems="center">
          <Icon as={FiGlobe} mr={2} /> {currentCountryName}{" "}
          <Icon as={FiChevronDown} ml={2} />
        </Flex>
      </MenuButton>
      <MenuList className="country-selector-options" py={0}>
        {listOfOptions.map((option, index) => {
          const isFirst = index === 0
          const isLast = index === listOfOptions.length - 1
          return (
            <MenuItem
              className={`country-option-${index + 1}`}
              borderTopRadius={isFirst ? 5 : 0}
              borderBottomRadius={isLast ? 5 : 0}
              as={ChakraLink}
              key={option.label}
              isDisabled={option.label === currentCountryName}
              href={option.url}
              rel="noopener"
              isExternal
            >
              {option.label}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default CountrySelector
