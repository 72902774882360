import { extendTheme } from "@chakra-ui/react"
import { AppEntityHydrated, webappThemeConfig } from "@jackfruit/common"
import builderConfig from "../../../config/config.json"
import { getColorScales } from "./utils"

export const config = webappThemeConfig

const { primaryColor, secondaryColor } = builderConfig
const { colors, components } = webappThemeConfig
const { primary: defaultPrimary, secondary: defaultSecondary } = colors

const primary = primaryColor ? getColorScales(primaryColor) : defaultPrimary
const secondary = secondaryColor
  ? getColorScales(secondaryColor)
  : defaultSecondary

const themeConfig = {
  ...webappThemeConfig,
  colors: {
    ...colors,
    primary,
    secondary,
  },
  components: {
    ...components,
    Button: {
      ...components.Button,
    },
  },
}

const theme = extendTheme(themeConfig)

export const makeTheme = (appConfig: AppEntityHydrated) => {
  const { primaryColor, secondaryColor } = appConfig
  const { colors, components } = webappThemeConfig
  const { primary: defaultPrimary, secondary: defaultSecondary } = colors

  const primary = primaryColor ? getColorScales(primaryColor) : defaultPrimary
  const secondary = secondaryColor
    ? getColorScales(secondaryColor)
    : defaultSecondary

  const themeConfig = {
    ...webappThemeConfig,
    colors: {
      ...colors,
      primary,
      secondary,
    },
    components: {
      ...components,
      Button: {
        ...components.Button,
      },
    },
  }
  return extendTheme(themeConfig)
}

export default theme
