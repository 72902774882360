import {
  AvailableLanguages,
  ClientEntity,
  ClientPrintServiceEntity,
  EntityId,
  PrinticularCategoryEntity,
  PrinticularOrder,
  PrinticularProductTemplateCategoryEntity,
  PrinticularProductTemplateEntity,
  PrinticularProductTemplateGroupEntity,
  PrinticularProductTemplateGroupPivotEntity,
  PrinticularProductTemplateTagEntity,
  PrintServiceEntity,
  PrintServiceProductCategoryEntity,
  PrintServiceProductCategoryImageEntity,
  PrintServiceProductEntity,
  PrintServiceProductImageEntity,
  PrintServiceProductPriceEntity,
} from "@jackfruit/common"
import { flatten } from "lodash"
import qs from "qs"
import { AddressLocationEntity } from "~/interfaces/entities/AddressLocation"
import { ApiException } from "~/interfaces/entities/ApiException"
import { AuthResume } from "~/interfaces/entities/AuthResume"
import { AuthUser } from "~/interfaces/entities/AuthUser"
import { AccountWithOrders } from "~/interfaces/entities/autopilot/AccountWithOrders"
import { Order } from "~/interfaces/entities/autopilot/Order"
import { ContentEntity, ContentType } from "~/interfaces/entities/Content"
import { ImageEntity } from "~/interfaces/entities/Image"
import { LatLng } from "~/interfaces/entities/LatLng"
import { StoreEntity } from "~/interfaces/entities/Store"
import { TemporaryCredentials } from "~/interfaces/entities/TemporaryCredentials"
import { UploadEntity } from "~/interfaces/entities/Upload"
import { logger } from "~/services/Logger"
import {
  convertToEntities,
  convertToEntity,
  JsonApiResponse,
  JsonApiResponseArray,
} from "~/services/Utils"
import { isOrderAlreadyPlaced } from "./ApiExceptionHelper"
import { PrinticularSerializer } from "./PrinticularSerializer"

const parseError = (error: any): ApiException => {
  if (error?.errors) {
    return {
      code: error.errors[0].code,
      message: error.error?.message ?? error.errors[0].title,
      errors: error.errors,
    }
  }

  if (error.error) {
    return {
      code: error.error.code,
      message: error.error.message,
    }
  }

  if (error.message) {
    return {
      code: "Unspecified",
      message: error.message,
    }
  }

  return {
    code: "Unspecified",
    message: JSON.stringify(error),
  }
}

export interface PlaceOrderResult {}

export interface S3Resource {
  accessKeyId: string
  bucket: string
  location: string
  policy: string
  signature: string
}

export interface GetStoreAutocompleteParams {
  filter: {
    text: string
    country: string // USA,PRI
    printServices: string // 2,3
    productIds?: string // 123,234
    radius: number // 100
    storeLimit: number // 6
    locationLimit: number // 10
    retailerIds?: string // 1,2,3
  }
}

type QueryStringParam =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | { [key: string]: QueryStringParam }

const RETRY_LIMIT = 4
export class PrinticularApi {
  private accessToken: string
  private deviceToken: string
  private baseUrl: string
  private headers: any
  private language: AvailableLanguages

  constructor(
    baseUrl: string,
    accessToken: string,
    deviceToken: string,
    language: AvailableLanguages
  ) {
    this.baseUrl = `${baseUrl}`
    this.accessToken = accessToken
    this.deviceToken = deviceToken
    this.language = language
  }

  public setDeviceToken(deviceToken: string) {
    this.deviceToken = deviceToken
  }

  public getDeviceToken() {
    return this.deviceToken
  }

  public setAccessToken(token: string) {
    this.accessToken = token
  }

  private async get<T = any>(url: string): Promise<T> {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }

      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "GET",
      })

      if (response.status !== 200) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }

      const data = await response.json()

      return data
    } catch (error) {
      throw error
    }
  }

  private async post(url: string, params: any) {
    try {
      this.headers = {
        Authorization: `Bearer ${this.accessToken}`,
        "Content-Type": "application/json",
        "Accept-Language": `${this.language ?? "en-US"},en;q=0.9`,
      }

      const response = await fetch(`${this.baseUrl}/${url}`, {
        headers: this.headers,
        method: "POST",
        body: JSON.stringify(params),
      })

      if ([200, 201, 204].indexOf(response.status) === -1) {
        const error = await response.json()
        const parsedError = parseError(error)

        if ([400, 401].indexOf(response.status) === -1) {
          logger.error(
            Error(`code: ${parsedError.code} message: ${parsedError.message}`)
          )
        }

        throw parsedError
      }
      const data = await response.json()

      return data
    } catch (error) {
      throw error
    }
  }

  /**
   * @deprecated
   */
  public async getPrintServiceDetails(printServiceId: EntityId): Promise<{
    client: ClientEntity
    clientPrintService: ClientPrintServiceEntity
    printService: PrintServiceEntity
    products: PrintServiceProductEntity[]
    productImages: PrintServiceProductImageEntity[]
    productPrices: PrintServiceProductPriceEntity[]
    productCategories: PrintServiceProductCategoryEntity[]
  }> {
    const url = `api/1.0/clients/this/printServices/${printServiceId}?include=client,products,products.categories,products.groups,products.prices`

    let data = null
    let retry = RETRY_LIMIT

    while (true) {
      try {
        data = await this.get(url)
        break
      } catch (e) {
        if (retry-- === 0) {
          throw e
        }
      }
    }

    const printServiceData = data.data
    const clientData = data.included.find(
      (entity: any) => entity.type === "clients"
    )
    const clientprintServiceData = data.included.find(
      (entity: any) => entity.type === "client_print_services"
    )
    const productsData = data.included.filter(
      (entity: any) => entity.type === "products"
    )
    const productImagesData = data.included.filter(
      (entity: any) => entity.type === "product_images"
    )
    const productPricesData = data.included.filter(
      (entity: any) => entity.type === "prices"
    )
    const productCategoriesData = data.included.filter(
      (entity: any) => entity.type === "categories"
    )
    const productCategoryImagesData = data.included.filter(
      (entity: any) => entity.type === "category_images"
    )

    const client = convertToEntity<ClientEntity>(clientData)
    const printService = convertToEntity<PrintServiceEntity>(printServiceData)
    const clientPrintService = convertToEntity<ClientPrintServiceEntity>(
      clientprintServiceData
    )
    const products = productsData.map((product: any) =>
      convertToEntity<PrintServiceProductEntity>(product)
    )
    const productImages = productImagesData.map((productImage: any) =>
      convertToEntity<PrintServiceProductImageEntity>(productImage)
    )
    const productPrices = productPricesData.map((productPrice: any) =>
      convertToEntity<PrintServiceProductPriceEntity>(productPrice)
    )

    const productCategories: any[] = productCategoriesData.map(
      (productCategory: any) =>
        convertToEntity<PrintServiceProductCategoryEntity>(productCategory)
    )

    const productCategoryImages: PrintServiceProductCategoryImageEntity[] =
      productCategoryImagesData.map((productCategoryImage: any) =>
        convertToEntity<PrintServiceProductCategoryImageEntity>(
          productCategoryImage
        )
      )

    productCategories.forEach((category, index, collection) => {
      const images = productCategoryImages.filter(image =>
        category.categoryImages.includes(image.id)
      )
      // fix missing sort order
      if (category.sortOrder === null) {
        category.sortOrder = 0
      }
      collection[index].images = images
    })

    return {
      printService,
      client,
      clientPrintService,
      products,
      productImages,
      productPrices,
      productCategories,
    }
  }

  /**
   * Get list of available stores for specific coordinates
   * @deprecated
   */
  public async getAvailableStores(
    latLng: LatLng,
    printServiceId: EntityId,
    printServiceProductIds: EntityId[]
  ): Promise<StoreEntity[]> {
    const productIdList = printServiceProductIds.join(",")

    if (latLng.lat === undefined || latLng.lng === undefined) {
      throw new Error("Invalid latLng")
    }

    const url = `api/1.0/printServices/${printServiceId}/stores?include=products&sort[latitude]=${latLng.lat}&sort[longitude]=${latLng.lng}&filter[products]=[${productIdList}]`

    const data = await this.get(url)

    return data.data.map((store: any) => convertToEntity<StoreEntity>(store))
  }

  /**
   * Get remote store details
   */
  public async getStoreDetails({
    remotePrintServiceId,
    remoteStoreId,
  }: {
    remotePrintServiceId: EntityId
    remoteStoreId: EntityId
  }): Promise<StoreEntity> {
    const response = await this.get(
      `api/v2/client/print-services/${remotePrintServiceId}/stores/${remoteStoreId}`
    )

    return convertToEntity<StoreEntity>(response.data)
  }

  /**
   * Get remote content
   */
  public async getContentList({
    contentType,
  }: {
    contentType: ContentType
  }): Promise<ContentEntity[]> {
    const response = await this.get<JsonApiResponseArray>(
      `api/v2/client/content` +
        this.queryString({
          filter: {
            type: contentType,
          },
        })
    )
    return response.data.map(entity => convertToEntity<ContentEntity>(entity))
  }

  public async getContent({ id }: { id: string }): Promise<ContentEntity> {
    const response = await this.get<JsonApiResponse>(
      `api/v2/client/content/${id}`
    )
    return convertToEntity<ContentEntity>(response.data)
  }

  /**
   *  Get list of available stores for specific coordinates and print services
   *  @deprecated
   */
  public async getAvailableStoresForMultiplePrintServices(
    latLng: LatLng,
    printServiceIds: EntityId[],
    printServiceProductIds: EntityId[]
  ): Promise<StoreEntity[]> {
    const results = await Promise.all(
      printServiceIds.map(async printServiceId =>
        this.getAvailableStores(latLng, printServiceId, printServiceProductIds)
      )
    )

    return flatten(results)
  }

  /**
   * Get list of available server templates
   *
   * @param payload
   *    @param preTags - The initial and complete list of tags for the current block
   *    @param tags - The filtered list of tags (user selection)
   *    @param limit - The pagination limit
   *    @param offset - The pagination offset (page * limit = offset)
   *
   * @returns PrinticularProductTemplateEntity[]
   */
  public async getAvailableProductTemplates(payload: {
    preTags?: EntityId[]
    tags?: EntityId[]
    limit?: number
    offset?: number
    keyword?: string
    printServiceIds?: EntityId[]
    templateTypes?: string[]
  }): Promise<{
    productTemplates: PrinticularProductTemplateEntity[]
    productTemplateCategories: PrinticularProductTemplateCategoryEntity[]
    categories: PrinticularCategoryEntity[]
    totalRemoteProducts: number
  }> {
    const {
      tags = [],
      preTags = [],
      limit = 25,
      offset = 0,
      keyword = "",
      printServiceIds = [],
      templateTypes = [],
    } = payload

    const tagsList = [...tags].sort().join(",")
    const preTagsList = [...preTags].sort().join(",")

    const resource = "api/v2/client/product-templates"
    const params: any = {
      sort: "-sortOrder,-clientTemplateSortOrder",
      "filter[keyword]": keyword,
      "filter[pretags]": preTagsList,
      "filter[tags]": tagsList,
      "page[limit]": limit,
      "page[offset]": offset,
      include:
        "ProductTemplate.productTemplateGroupPivots,ProductTemplateGroupPivot.productTemplateGroup",
    }

    if (templateTypes.length > 0) {
      params["filter[templateType]"] = templateTypes.sort().join(",")
    }

    if (printServiceIds.length > 0) {
      params["filter[printServices]"] = printServiceIds
        .sort((a: EntityId, b: EntityId) => Number(a) - Number(b))
        .join(",")
    }

    const queryString = Object.keys(params)
      .sort()
      .map(key => key + "=" + params[key])
      .join("&")

    const result = await this.get(`${resource}?${queryString}`)

    const { data, meta } = result
    const included = result.included ?? []

    const productTemplatesData = data

    const productTemplates: PrinticularProductTemplateEntity[] =
      productTemplatesData.map((template: any) =>
        convertToEntity<PrinticularProductTemplateEntity>(template)
      )

    const productTemplateCategoriesData = included.filter(
      (entity: any) => entity.type === "ProductTemplateCategory"
    )
    const productTemplateCategories: PrinticularProductTemplateCategoryEntity[] =
      productTemplateCategoriesData.map((productTemplateCategory: any) =>
        convertToEntity<PrinticularProductTemplateCategoryEntity>(
          productTemplateCategory
        )
      )
    const categoriesData = included.filter(
      (entity: any) => entity.type === "Category"
    )
    const categories: PrinticularCategoryEntity[] = categoriesData.map(
      (category: any) => convertToEntity<PrinticularCategoryEntity>(category)
    )

    const templateGroupPivotsData = included.filter(
      (entity: any) => entity.type === "ProductTemplateGroupPivot"
    )

    const templateGroupPivots: PrinticularProductTemplateGroupPivotEntity[] =
      templateGroupPivotsData.map((pivot: any) =>
        convertToEntity<PrinticularProductTemplateGroupPivotEntity>(pivot)
      )

    const productTemplateGroupsData = included.filter(
      (entity: any) => entity.type === "ProductTemplateGroup"
    )

    const templateGroups: PrinticularProductTemplateGroupEntity[] =
      productTemplateGroupsData.map((group: any) =>
        convertToEntity<PrinticularProductTemplateGroupEntity>(group)
      )

    // attach categories details to each template
    // for ease of use
    productTemplates.forEach((template, index, orig) => {
      const templateCategory = productTemplateCategories.find(
        tc => tc.id === template.productTemplateCategories[0]
      )
      const category = categories.find(c => c.id === templateCategory?.category)
      const groupIds = templateGroupPivots
        .filter(pivot => template.productTemplateGroupPivots.includes(pivot.id))
        .map(pivot => pivot.productTemplateGroup)

      const groups = templateGroups.filter(group => groupIds.includes(group.id))

      orig[index].categoryName = category?.name ?? ""
      orig[index].categoryDisplayName = category?.displayName ?? ""
      orig[index].pageCount = template.variants[0].pages.length
      orig[index].templateGroups = groups.map(group => {
        return {
          name: group.name,
          type: group.groupType,
        }
      })
    })

    return {
      productTemplates,
      productTemplateCategories,
      categories,
      totalRemoteProducts: meta.foundRows,
    }
  }

  /**
   * Get remote tags list for a given list of slugs
   * (usefull to collect tags's title to be displayed on filters)
   */
  public async getProductTemplateTags(
    tags: EntityId[] = []
  ): Promise<PrinticularProductTemplateTagEntity[]> {
    const tagList = tags.sort().join(",")
    const url = `api/v2/client/product-templates/tags?filter[tags]=${tagList}`
    const { data } = await this.get<JsonApiResponseArray>(url)

    const entities = data.map(tag =>
      convertToEntity<PrinticularProductTemplateTagEntity>(tag)
    )

    return entities.map((entity: PrinticularProductTemplateTagEntity) => {
      return {
        ...entity,
        isFetchingTemplates: false,
      }
    })
  }

  /**
   * @deprecated
   * Register an S3 image upload to autopilot
   */
  public async registerImage(upload: UploadEntity): Promise<ImageEntity> {
    const url = `api/1.0/users/0/images`

    const data = await this.post(url, {
      meta: {
        device_token: this.deviceToken,
      },
      data: {
        type: "images",
        attributes: {
          external_url: upload.location,
        },
      },
    })

    return convertToEntity<ImageEntity>(data.data)
  }

  /**
   * @deprecated
   * Place the order on autopilot
   */
  public async placeOrder(payload: any): Promise<PrinticularOrder> {
    const url = `api/1.0/users/0/orders?include=giftCertificate,lineItems,products,shippingMethods,store`

    try {
      const data = await this.post(url, payload)

      return PrinticularSerializer.deserializeOrder(data)
    } catch (error: any) {
      // in case nonce match with existing order
      // we need to fake an order for the success page
      // to display correctly
      if (isOrderAlreadyPlaced(error)) {
        const fakeOrder: any = {
          id: 1,
          lineItems: [],
          shippingMethods: [],
        }

        return fakeOrder as PrinticularOrder
      } else {
        throw error
      }
    }
  }

  /**
   * Get location suggestions
   */
  public async getStoreAutocomplete(
    params: GetStoreAutocompleteParams
  ): Promise<AddressLocationEntity[]> {
    function alphabeticalSort(a: string, b: string) {
      return a.localeCompare(b)
    }

    const query = qs.stringify(params, { sort: alphabeticalSort }).toString()
    const url = `api/v2/client/stores/autocomplete?${query}`
    const data = await this.get(url)

    return data.data.map((autocomplete: any) =>
      convertToEntity<AddressLocationEntity>(autocomplete)
    )
  }

  /**
   * return temporary credentials to use with aws map provider
   */
  public async getTempCredentials() {
    const url = "api/v2/client/stores/credentials"
    const data = await this.get(url)

    return convertToEntity<TemporaryCredentials>(data.data)
  }

  /**
   * authenticate user
   */
  public async login({ login, password }: { login: string; password: string }) {
    const url = "api/v2/client/account/login"
    const data = await this.post(url, {
      data: {
        type: "AccountLogin",
        attributes: {
          emailAddress: login,
          password: password,
          deviceId: this.getDeviceToken(),
        },
      },
    })

    return convertToEntity<AuthUser>(data.data)
  }

  public async register(payload: { emailAddress: string; siteUrl: string }) {
    const { emailAddress, siteUrl } = payload

    const response = await this.post(`api/v2/client/account/register`, {
      data: {
        type: "AccountRegister",
        attributes: {
          emailAddress,
          deviceId: this.getDeviceToken(),
          resetUrl: new URL("reset-password/", siteUrl).toString(),
          registerUrl: new URL("register/confirm/", siteUrl).toString(),
        },
      },
    })

    return convertToEntity(response.data)
  }

  /**
   * try to login user silently on page refresh
   */
  public async loginSilently({ token }: { token: string }) {
    // force token
    this.accessToken = token
    const authResume = await this.resumeLogin()

    const [userInfos] = authResume.clientUser
    const partialAuthUser: AuthUser = {
      ...userInfos,
      loginToken: authResume.token,
    }

    return partialAuthUser
  }

  /**
   * Refresh user auth session and get his informations
   */
  public async resumeLogin() {
    const response = await this.post(
      `api/v2/client/account/resume?include=ResumeLogin.clientUser`,
      {
        data: {
          type: "ResumeLogin",
          attributes: {
            deviceId: this.getDeviceToken(),
          },
        },
      }
    )

    return convertToEntities<AuthResume>(response)
  }

  public async registerConfirmation(payload: {
    token: string
    password: string
  }) {
    const { password, token } = payload

    const response = await this.post(`api/v2/client/account/register/confirm`, {
      data: {
        type: "AccountConfirm",
        attributes: {
          token,
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async forgotPassword(payload: {
    emailAddress: string
    siteUrl: string
  }) {
    const { emailAddress, siteUrl } = payload

    const response = await this.post(`api/v2/client/account/forgot`, {
      data: {
        type: "AccountForgot",
        attributes: {
          emailAddress,
          deviceId: this.deviceToken,
          resetUrl: new URL("reset-password/", siteUrl).toString(),
        },
      },
    })

    return convertToEntity(response.data)
  }

  /**
   * Get user account details
   */
  public async getUserAccount() {
    const response = await this.get(`api/v2/client/account`)
    return convertToEntity<AuthUser>(response.data)
  }

  /**
   * Get user account orders
   */
  public async getUserAccountOrders(): Promise<AccountWithOrders> {
    const response = await this.get(
      `api/v2/client/account?include=LineItem.originalImage,LineItem.processedImage,Order.suborders,Order.thumbnailImage,Product.productImages`
    )

    return convertToEntities<AccountWithOrders>(response)
  }

  /**
   * Get a user order
   */
  public async getUserOrder(orderId: EntityId): Promise<Order> {
    const response = await this.get(
      `api/v2/client/orders/${orderId}?include=Order.suborders,Product.productImages`
    )

    return convertToEntities<Order>(response)
  }

  /*
   * Update user account details
   */
  public async updateUserAccount(payload: {
    id: EntityId
    name: string
    phoneNumber: string
  }) {
    const { id, name, phoneNumber } = payload

    const response = await this.post(`api/v2/client/account`, {
      data: {
        id,
        type: "ClientUser",
        attributes: {
          name,
          phoneNumber,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async resetPassword(payload: { password: string; token: string }) {
    const { password, token } = payload

    const response = await this.post(`api/v2/client/account/reset`, {
      data: {
        type: "AccountReset",
        attributes: {
          token,
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async changePassword(payload: { password: string }) {
    const { password } = payload

    const response = await this.post(`api/v2/client/account/change-password`, {
      data: {
        type: "AccountChangePassword",
        attributes: {
          password,
        },
      },
    })

    return convertToEntity<AuthUser>(response.data)
  }

  public async ping() {
    const response = await this.get(`api/v2/ping`)

    return response
  }

  private queryString(
    params: { [key: string]: QueryStringParam },
    prefix = "?"
  ): string {
    let query = []
    for (const key in params) {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          // Array of string/numbers, convert to comma separated string
          query.push(
            encodeURIComponent(key) +
              "=" +
              (params[key] as string[] | number[]).join(",")
          )
        } else if (typeof params[key] === "object") {
          // Nested object, convert to square bracket notation
          const p = params[key] as { [key: string]: QueryStringParam }
          for (const subKey in p) {
            const obj: QueryStringParam = {}
            obj[`${key}[${subKey}]`] = p[subKey]
            query.push(this.queryString(obj, ""))
          }
        } else {
          // Simple string/number/boolean, just add it
          query.push(
            encodeURIComponent(key) +
              "=" +
              encodeURIComponent(params[key] as string | number | boolean)
          )
        }
      }
    }
    if (query.length) {
      return prefix + query.join("&")
    }
    return ""
  }
}
