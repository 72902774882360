import { ChakraProvider } from "@chakra-ui/react"
import { replace } from "@wordpress/shortcode"
import React from "react"
import { renderToString } from "react-dom/server"
import AppStoreButtons from "~/components/common/AppStoreButtons"
import { ShortCodeParser, ShortCodeStateEntities } from "../replaceShortCodes"
import { makeTheme } from "~/@chakra-ui/gatsby-plugin/theme"

export const parser: ShortCodeParser = (
  html: string = "",
  state: ShortCodeStateEntities
) => {
  return replace("appButtons", html, args => {
    const justify = args.attrs.named.justify ?? "left"
    if (!state.appConfig) {
      return ""
    }

    const theme = makeTheme(state.appConfig!)

    const { iosStoreUrl, androidStoreUrl, language } = state.appConfig

    const rendered = renderToString(
      <ChakraProvider theme={theme}>
        <AppStoreButtons
          justify={justify}
          direction="column"
          appStoreUrl={iosStoreUrl}
          googlePlayUrl={androidStoreUrl}
          language={language}
        />
      </ChakraProvider>
    )

    return rendered
  })
}
